import * as chroma from 'chroma.ts'

export function generateColors(size: number) {
  return chroma.scale('Spectral').classes(size).colors(size)
}

export function setToDefault(obj: any) {
  // TODO
  /*
  -> generischer weg object zu säubern
  1. default values
  2. undefined wo keine value
  3. leere unterobjekte -> kreislauf
  */

  // Object.assign(antragContainer, {})
  // const baseObj = {} as NotdienstAenderungAntragDetailContainer
  const props = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < props.length; i++) {
    const key = props[i]! as keyof typeof obj
    // const value = baseObj[key] ?? undefined
    // antragContainer[key] = value ?? undefined
    obj[key] = undefined
  }
}

// dateStyle: 'medium'
export const df = Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeZone: 'Europe/Berlin' })
// dateStyle: 'medium', timeStyle: 'short'
export const dtf = Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Europe/Berlin' })

export const dfWeekday = Intl.DateTimeFormat('de-DE', { weekday: 'short', timeZone: 'Europe/Berlin' })
export const dfWeekdayLong = Intl.DateTimeFormat('de-DE', { weekday: 'long', timeZone: 'Europe/Berlin' })
export const tf = Intl.DateTimeFormat('de-DE', { timeStyle: 'short', timeZone: 'Europe/Berlin' })

export function dfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return df.format(dateObj)
}

export function dtfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dtf.format(dateObj)
}

export function dfWeekdayNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dfWeekday.format(dateObj)
}

export function dfWeekdayLongNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dfWeekdayLong.format(dateObj)
}

export function tfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return tf.format(dateObj)
}
