<script lang="ts">
const theme = 'w-full border border-gray-300 rounded-lg bg-gray-100 p-4 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400'
const themeDone = 'w-full border border-green-300 rounded-lg bg-green-50 p-4 text-green-700 dark:border-green-800 dark:bg-gray-700 dark:text-green-400'
const themeActive = 'w-full border border-blue-300 rounded-lg bg-blue-100 p-4 text-blue-700 dark:border-blue-800 dark:bg-gray-700 dark:text-blue-400'
</script>
<script lang="ts" setup>
const props = defineProps({
  label: String,
  sr: String,
  active: Boolean,
  done: Boolean,
})
const emit = defineEmits(['click'])

function click(): void {
  emit('click')
}





const currentTheme = computed(() => props.active ? themeActive : props.done ? themeDone : theme)
</script>

<template>
  <li>
    <div class="cursor-pointer" :class="currentTheme" role="alert" @click="click">
      <div class="flex items-center justify-between">
        <span v-if="props.sr" class="sr-only">{{ props.sr }}</span>
        <h3 class="font-medium">
          {{ label }}
        </h3>
        <svg v-if="done" class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
        </svg>
        <svg v-if="active" class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </div>
    </div>
  </li>
</template>
