import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type {
  Apotheke,
  ApothekeCluster,
  ApothekeContainer,
  ApothekeGeocodeResultContainer,
  ApothekeImportInfo,
  ApothekeSuchContainer,
  EntfernungMapContainer,
  JwtResponse,
  KarteContainer,
  NnfDateiAnfrageContainer,
  NnfProtokoll,
  NnfProtokollContainer,
  NotdienstAenderungAntragContainer,
  NotdienstAenderungAntragDetailContainer,
  NotdienstAenderungAntragFreigabeAblehnenContainer,
  NotdienstAenderungAntragReduced,
  NotdienstAenderungAntragSucheContainer,
  NotdienstPlansucheContainer,
  NotdienstPlansucheSuchergebnisContainer,
  NotdienstStatistik,
  NotdienstStatistikContainer,
  Nutzer,
  NutzerContainer,
  NutzerDataContainer,
  Plan,
  PlanContainer,
  Rezeptsammelstelle,
  RezeptsammelstelleContainer,
  RezeptsammelstelleSuchContainer,
} from '~/notdienstportal'

const baseUrl = import.meta.env.DEV ? 'http://localhost:8084/' : '/api/'
const prefix = 'prt'

async function createDownload(url: string, body?: any) {
  let response

  if (body) {
    response = await axios.post(url, body, { responseType: 'blob', ...getConfig() })
  }
  else {
    response = await axios.get(url, { responseType: 'blob', ...getConfig() })
  }

  const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]

  const responseUrl = globalThis.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = responseUrl
  link.setAttribute('download', fileName)
  link.click()

  return response
}

/*
// Add a response interceptor
axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})
*/

function getConfig() {
  const userStore = useUserStore()
  const config = {
    headers: {
      Authorization: '',
    },
  }

  if (userStore.token)
    config.headers.Authorization = `Bearer ${userStore.token}`

  return config
}

export const api = {
  // Auth
  login(nutzername: string, passwort: string): Promise<AxiosResponse<JwtResponse>> {
    return axios.post(`${baseUrl}apipub/authenticate`, { username: nutzername, password: passwort })
  },

  // Nutzer
  getNutzerInfo(): Promise<AxiosResponse<NutzerDataContainer>> {
    return axios.get(`${baseUrl}${prefix}/nutzer`, getConfig())
  },
  updateNutzerInfo(obj: Nutzer): Promise<AxiosResponse<NutzerContainer>> {
    return axios.post(`${baseUrl}${prefix}/nutzer`, obj, getConfig())
  },
  searchNutzer(): Promise<AxiosResponse<Array<Nutzer>>> {
    return axios.get(`${baseUrl}${prefix}/nutzer/uebersicht`, getConfig())
  },
  loadNutzer(id: number): Promise<AxiosResponse<NutzerContainer>> {
    return axios.get(`${baseUrl}${prefix}/nutzer/details/${id}`, getConfig())
  },
  saveNutzer(obj: Nutzer): Promise<AxiosResponse<NutzerContainer>> {
    return axios.post(`${baseUrl}${prefix}/nutzer/speichern`, obj, getConfig())
  },
  deleteNutzer(id: number): Promise<AxiosResponse<NutzerContainer>> {
    return axios.delete(`${baseUrl}${prefix}/nutzer/loeschen/${id}`, getConfig())
  },

  // Apotheken
  searchApothekeContainer(container: ApothekeSuchContainer): Promise<AxiosResponse<Array<Apotheke>>> {
    return axios.post(`${baseUrl}${prefix}/apotheke/uebersicht`, container, getConfig())
  },
  loadApotheke(id: number): Promise<AxiosResponse<ApothekeContainer>> {
    return axios.get(`${baseUrl}${prefix}/apotheke/details/${id}`, getConfig())
  },
  searchApothekenCluster(container: any): Promise<AxiosResponse<Array<ApothekeCluster>>> {
    return axios.post(`${baseUrl}${prefix}/apotheke/cluster`, container, getConfig())
  },
  geokodierenBerichtigen(apoId: string | number, fallbackToHereMap: boolean): Promise<AxiosResponse<Array<ApothekeGeocodeResultContainer>>> {
    return axios.get(`${baseUrl}${prefix}/apotheke/geokodieren/berichtigen/${apoId}/${fallbackToHereMap}`, getConfig())
  },
  geokodierenRegionBerichtigen(region: string, fallbackToHereMap: boolean): Promise<AxiosResponse<Array<ApothekeGeocodeResultContainer>>> {
    return axios.get(`${baseUrl}${prefix}/apotheke/geokodieren/berichtigen/${region}/2022/${fallbackToHereMap}`, getConfig())
  },
  uploadFileApotheke(body: any): Promise<AxiosResponse<ApothekeImportInfo>> {
    return axios.post(`${baseUrl}${prefix}/apotheke/importFile`, body, getConfig())
  },
  geokodierenManuell(apoId: any, lat: any, lng: any): Promise<AxiosResponse<ApothekeImportInfo>> {
    return axios.get(`${baseUrl}${prefix}/apotheke/geokodieren/manuell/${apoId}/${lat}/${lng}`, getConfig())
  },

  // Rezeptsammelstellen
  searchRst(container: RezeptsammelstelleSuchContainer): Promise<AxiosResponse<Array<Rezeptsammelstelle>>> {
    return axios.post(`${baseUrl}${prefix}/rezeptsammelstelle/uebersicht`, container, getConfig())
  },
  loadRst(id: number): Promise<AxiosResponse<RezeptsammelstelleContainer>> {
    return axios.get(`${baseUrl}${prefix}/rezeptsammelstelle/details/${id}`, getConfig())
  },
  geokodierenBerichtigenRst(id: string | number, fallbackToHereMap: boolean): Promise<AxiosResponse<Array<ApothekeGeocodeResultContainer>>> {
    return axios.get(`${baseUrl}${prefix}/rezeptsammelstelle/geokodieren/berichtigen/${id}/${fallbackToHereMap}`, getConfig())
  },
  saveRst(obj: RezeptsammelstelleContainer): Promise<AxiosResponse<RezeptsammelstelleContainer>> {
    return axios.post(`${baseUrl}${prefix}/rezeptsammelstelle/speichern`, obj, getConfig())
  },
  deleteRst(id: number): Promise<AxiosResponse<RezeptsammelstelleContainer>> {
    return axios.delete(`${baseUrl}${prefix}/rezeptsammelstelle/loeschen/${id}`, getConfig())
  },
  async reportRst(id: any, umkreis: any) {
    const url = `${baseUrl}${prefix}/rezeptsammelstelle/report/${id}/${umkreis}`
    return createDownload(url)
  },

  // Entfernung
  ladenApothekenEntfernungenMapContainer(): Promise<AxiosResponse<EntfernungMapContainer>> {
    return axios.get(`${baseUrl}${prefix}/entfernung/laden/apotheken`, getConfig())
  },
  berechnenApothekenEntfernungen(): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/entfernung/berechnen/apotheken`, getConfig())
  },
  pruefenApothekenEntfernungen(jahr: number | string, sourceRegion: string, targetRegion: string): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/entfernung/pruefen/apotheken/${jahr}/${sourceRegion}/${targetRegion}`, getConfig())
  },
  pruefenApothekenEntfernungenAlleNachbarn(jahr: number | string, sourceRegion: string): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/entfernung/pruefen/apotheken/${jahr}/${sourceRegion}`, getConfig())
  },

  // Karte
  searchKarte(container: ApothekeSuchContainer): Promise<AxiosResponse<KarteContainer>> {
    return axios.post(`${baseUrl}${prefix}/karte/suche`, container, getConfig())
  },

  // Plan
  searchPlan(): Promise<AxiosResponse<Array<Plan>>> {
    return axios.get(`${baseUrl}${prefix}/plan/uebersicht`, getConfig())
  },
  loadPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/details/${id}`, getConfig())
  },
  savePlan(obj: PlanContainer): Promise<AxiosResponse<PlanContainer>> {
    return axios.post(`${baseUrl}${prefix}/plan/speichern`, obj, getConfig())
  },
  deletePlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.delete(`${baseUrl}${prefix}/plan/loeschen/${id}`, getConfig())
  },
  // Plan Actions
  berechnenPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/berechnen/${id}`, getConfig())
  },
  freigebenPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/freigabe/${id}`, getConfig())
  },
  freigebenAufhebenPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/freigabe/aufheben/${id}`, getConfig())
  },
  abrechnenPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/abrechnen/${id}`, getConfig())
  },
  kopierenPlan(id: number): Promise<AxiosResponse<PlanContainer>> {
    return axios.get(`${baseUrl}${prefix}/plan/kopieren/${id}`, getConfig())
  },
  async reportDienstvergleich(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/dienste`
    return createDownload(url, container)
  },
  async reportFehlerquote(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/fehlerquote`
    return createDownload(url, container)
  },
  async reportFehlerquotePlanId(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/fehlerquote/planid`
    return createDownload(url, container)
  },
  async reportNachbarschaftsanalyse(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/nachbarschaft`
    return createDownload(url, container)
  },
  async reportServicewerte(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/servicewerte`
    return createDownload(url, container)
  },
  async reportServiceErholungstage(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/serviceerholungstage`
    return createDownload(url, container)
  },
  async reportFilialverbundserviceerholungstage(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/filialverbundserviceerholungstage`
    return createDownload(url, container)
  },
  getPlzRestriktion(region: string, apoId: number, distance: number): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/plan/report/konfiguration/erstelleplzrestriktion/${region}/${apoId}/${distance}`, getConfig())
  },
  async reportPlanPruefen(id: any) {
    const url = `${baseUrl}${prefix}/plan/pruefen/${id}`
    return createDownload(url)
  },
  async reportServiceGearing(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/verzahnung`
    return createDownload(url, container)
  },
  async reportParallelServices(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/paralleldienste`
    return createDownload(url, container)
  },
  async reportParallelServicesDistances(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/paralleldienste/entfernungen`
    return createDownload(url, container)
  },
  async reportDayTypeServiceCounter(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/tagtypdienstzaehler`
    return createDownload(url, container)
  },
  async reportFeiertage(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/feiertage`
    return createDownload(url, container)
  },
  async reportNotdienstaenderungenEntfernungen(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/notdienstaenderungen/entfernungen`
    return createDownload(url, container)
  },
  async reportApothekenNichtVerplant(container: any) {
    const url = `${baseUrl}${prefix}/plan/report/geoffnetnichtverplant`
    return createDownload(url, container)
  },
  async reportStatistikCsv(container: any) {
    const url = `${baseUrl}${prefix}/plan/notdienst/statistik/csv`
    return createDownload(url, container)
  },

  // Notdienst
  getNotdienste(container: NotdienstPlansucheContainer): Promise<AxiosResponse<NotdienstPlansucheSuchergebnisContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienst/plansuche`, container, getConfig())
  },
  getStatistik4Id(container: NotdienstStatistikContainer): Promise<AxiosResponse<Array<NotdienstStatistik>>> {
    return axios.post(`${baseUrl}${prefix}/notdienst/statistik`, container, getConfig())
  },
  async getStatistik4IdCsv(container: any) {
    const url = `${baseUrl}${prefix}/notdienst/statistik/csv`
    return createDownload(url, container)
  },
  async reportCSVNotdienst(region: any, jahr: any) {
    const url = `${baseUrl}${prefix}/notdienst/individuellesuche/csv/jahr/${region}/${jahr}`
    return createDownload(url)
  },

  // NotdienstÄnderungAntrag
  schrittAntrag(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/schritt`, obj, getConfig())
  },

  // NotdienstÄnderungAntrag - Übersicht/Detail
  searchAntrag(obj: NotdienstAenderungAntragSucheContainer): Promise<AxiosResponse<Array<NotdienstAenderungAntragReduced>>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/uebersicht`, obj, getConfig())
  },
  detailsAntrag(jahr: number, id: number): Promise<AxiosResponse<NotdienstAenderungAntragDetailContainer>> {
    return axios.get(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/details/${jahr}/${id}`, getConfig())
  },
  freigebenAntrag(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragFreigabeAblehnenContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/freigeben`, obj, getConfig())
  },
  ablehnenAntrag(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragFreigabeAblehnenContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/ablehnen`, obj, getConfig())
  },
  speichernAntrag(jahr: number, id: number, obj: any): Promise<AxiosResponse<NotdienstAenderungAntragFreigabeAblehnenContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/einzelantrag/speichern/${jahr}/${id}`, obj, getConfig())
  },

  // NotdienstÄnderungAntrag - Eröffnung
  schrittEroeffnung(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/eroeffnung/schritt`, obj, getConfig())
  },
  async reportCSVEroeffnung(obj: any) {
    const url = `${baseUrl}${prefix}/notdienstaenderungantrag/eroeffnung/csvstatistik`
    return createDownload(url, obj)
  },

  // NotdienstÄnderungAntrag - Schließung
  schrittSchliessung(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/schliessung/schritt`, obj, getConfig())
  },
  schrittSchliessungVorgang(id: number): Promise<AxiosResponse<NotdienstAenderungAntragContainer>> {
    return axios.get(`${baseUrl}${prefix}/notdienstaenderungantrag/schliessung/schritt/vorgang/${id}`, getConfig())
  },
  async reportCSVAntrag(jahr: any, antragId: any) {
    const url = `${baseUrl}${prefix}/notdienstaenderungantrag/csv/${jahr}/${antragId}`
    return createDownload(url)
  },
  sortSchliessung(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragContainer>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/schliessung/sortieren`, obj, getConfig())
  },
  async reportCSVSchliessung(obj: any) {
    const url = `${baseUrl}${prefix}/notdienstaenderungantrag/schliessung/csvstatistik`
    return createDownload(url, obj)
  },

  // NotdienstÄnderungAntrag - Schließung Vorgang
  startVorgangSchliessung(obj: any): Promise<AxiosResponse<any>> {
    return axios.post(`${baseUrl}${prefix}/notdienstaenderungantrag/schliessung/vorgangstarten`, obj, getConfig())
  },
  searchSchliessungsvorgang(obj: any): Promise<AxiosResponse<Array<SchliessungVorgang>>> {
    return axios.post(`${baseUrl}${prefix}/schliessungvorgang/uebersicht`, obj, getConfig())
  },
  verteilenbeendenSchliessungsvorgang(id: number): Promise<AxiosResponse<SchliessungVorgangContainer>> {
    return axios.get(`${baseUrl}${prefix}/schliessungvorgang/verteilenbeenden/${id}`, getConfig())
  },
  detailsSchliessungsvorgang(id: number): Promise<AxiosResponse<SchliessungVorgangContainer>> {
    return axios.get(`${baseUrl}${prefix}/schliessungvorgang/details/${id}`, getConfig())
  },
  speichernSchliessungsvorgang(obj: any): Promise<AxiosResponse<NotdienstAenderungAntragFreigabeAblehnenContainer>> {
    return axios.post(`${baseUrl}${prefix}/schliessungvorgang/speichern/`, obj, getConfig())
  },
  deleteSchliessungsvorgang(id: number): Promise<AxiosResponse<string>> {
    return axios.delete(`${baseUrl}${prefix}/schliessungvorgang/loeschen/${id}`, getConfig())
  },
  beendenSchliessungsvorgang(id: number): Promise<AxiosResponse<SchliessungVorgangContainer>> {
    return axios.get(`${baseUrl}${prefix}/schliessungvorgang/beenden/${id}`, getConfig())
  },

  // NNF
  searchNNF(): Promise<AxiosResponse<Array<NnfProtokoll>>> {
    return axios.get(`${baseUrl}${prefix}/nnf/uebersicht`, getConfig())
  },
  loadNNF(id: number): Promise<AxiosResponse<NnfProtokollContainer>> {
    return axios.get(`${baseUrl}${prefix}/nnf/details/${id}`, getConfig())
  },
  deleteNNF(id: number): Promise<AxiosResponse<NnfProtokollContainer>> {
    return axios.delete(`${baseUrl}${prefix}/nnf/loeschen/${id}`, getConfig())
  },
  anlegenNNF(container: any): Promise<AxiosResponse<NnfProtokollContainer>> {
    return axios.post(`${baseUrl}${prefix}/nnf/anlegen`, container, getConfig())
  },
  berechnenNNF(container: NnfProtokollContainer): Promise<AxiosResponse<NnfProtokollContainer>> {
    return axios.post(`${baseUrl}${prefix}/nnf/berechnen`, container, getConfig())
  },
  sendenNNF(container: NnfProtokollContainer): Promise<AxiosResponse<NnfProtokollContainer>> {
    return axios.post(`${baseUrl}${prefix}/nnf/senden`, container, getConfig())
  },
  async testDatei(container: NnfDateiAnfrageContainer) {
    const url = `${baseUrl}${prefix}/nnf/test/datei`
    return createDownload(url, container)
  },
  async testApothekeDatei(container: NnfDateiAnfrageContainer) {
    const url = `${baseUrl}${prefix}/nnf/test/apotheke/datei`
    return createDownload(url, container)
  },

  // Admin
  startBackup(): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/admin/backup`, getConfig())
  },
  startBackupAndRestore(): Promise<AxiosResponse<string>> {
    return axios.get(`${baseUrl}${prefix}/admin/backupAndRestoreOldDbs`, getConfig())
  },

  // Schnittstellenutzer
  searchSchnittstellenutzer(container: any): Promise<AxiosResponse<Array<any>>> {
    return axios.post(`${baseUrl}${prefix}/schnittstellenutzer/uebersicht`, container, getConfig())
  },
  loadSchnittstellenutzer(id: number): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}${prefix}/schnittstellenutzer/details/${id}`, getConfig())
  },
  deleteSchnittstellenutzer(id: number): Promise<AxiosResponse<any>> {
    return axios.delete(`${baseUrl}${prefix}/schnittstellenutzer/loeschen/${id}`, getConfig())
  },
  sendSchnittstellenutzerMail(id: number): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}${prefix}/schnittstellenutzer/sendmail/${id}`, getConfig())
  },
  deleteCacheFile(id: number): Promise<AxiosResponse<any>> {
    return axios.delete(`${baseUrl}${prefix}/schnittstellenutzer/loeschendatei/${id}`, getConfig())
  },
  showCacheFile(id: number) {
    return axios.get(`${baseUrl}${prefix}/schnittstellenutzer/zeigedateiinhalt/${id}`, getConfig())
  },

  // NewsletterNutzer
  searchNewsletterNutzer(container: any): Promise<AxiosResponse<Array<any>>> {
    return axios.post(`${baseUrl}${prefix}/newsletternutzer/uebersicht`, container, getConfig())
  },
  loadNewsletterNutzer(id: number): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}${prefix}/newsletternutzer/details/${id}`, getConfig())
  },
  deleteNewsletterNutzer(id: number): Promise<AxiosResponse<any>> {
    return axios.delete(`${baseUrl}${prefix}/newsletternutzer/loeschen/${id}`, getConfig())
  },
}
